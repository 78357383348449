@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

* {
    font-family: 'Yantramanav', sans-serif;
    transition: .4s ease-in-out;
}

html,
body {
    margin: 0;
    padding: 0;
}

a {
    color: #333333;
    text-decoration: none;
    display: inline-block;
}

span {
    display: inline-block;
}

p {
    font-size: 15px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

input {
    border-radius: 0;
}
input:focus, textarea:focus{
    box-shadow: none !important;
    
}
.container-fluid {
    padding: 0 30px;
}

.p-30 {
    padding: 30px 0;
}
.themeChange{
    position: fixed;
    right: 0;
    top: 50%;
    display: flex;
    align-items: start;
    overflow: hidden;
    transform: translateX(112px);
}
.themeChange.active{
    transform: translateX(0px);

}
.themeChange .themeChangeBtn{
    width: 50px;
    height: 50px;
    background-color: var(--dark-bg-orange);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;

}
.themeChange .themeChangeBtn svg {
    color: #fff;
    font-size: 36px;
    animation: themeBtn 2s linear infinite;
}
@keyframes themeBtn{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.themeChange .themeColor {
    background-color: #fff;
    padding: 20px;
    width: 112px;
    border: 1px solid var(--dark-bg-orange);
}
.themeChange .themeColor ul{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.themeChange .themeColor ul li{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--dark-blue);
    cursor: pointer;
}
.navbar-toggler{
    border-radius: 0;
    background-color: #ffffff;
    padding: 4px 6px;
}
.navbar-toggler:focus{
    box-shadow:none;
}

.backTop {
    background: var(--dark-bg-blue);
    bottom: 20px;
    box-shadow: 0 15px 10px -15px #a9a9a9;
    color: #fff;
    cursor: pointer;
    font-size: 28px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 20px;
    transition: .4s ease-in-out;
    width: 45px;
    z-index: 111;
    border-radius: 4px;
}
/* CSS Variable Start */
:root {
    /* Text Color */
    --dark-blue: #0543b5;
    --light-blue: #1877f2;

    --dark-orange: #cf5f00;
    --light-orange: #f89c1d;

    --white: #ffffff;
    --black: #333333;
    --gray: #d1d1d1;
    --border: #dee2e6;


    /* Bg Color */
    --dark-bg-blue: #0543b5;
    --light-bg-blue: #1877f2;

    --dark-bg-orange: #cf5f00;
    --light-bg-orange: #f89c1d;

    --white-bg: #ffffff;
    --black-bg: #333333;
    --gray-bg: #f8f7fa;
}

/* CSS Variable End */


/**** Header Start ****/

header.sticky.active {
    box-shadow: 0 15px 10px -15px #a9a9a9;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    -webkit-transform: translateY(-122px);
    transform: translateY(-122px);
    transition: .4s ease-in-out;
    width: 100%;
    z-index: 111;
}

/* Top Header Start */
.topBar {
    background: var(--light-blue);
}

.topBarInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socialIcons ul,
.loginSec .loginInfo {
    display: flex;
    column-gap: 16px;
    align-items: center;
}

.socialIcons ul li a {
    color: #ffffff;
    font-size: 12px;
}

.loginSec {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.loginSec ul li {
    display: flex;
    column-gap: 6px;
    align-items: center;
    text-transform: capitalize;
}

.loginSec ul li,
.loginSec ul li a {
    color: #ffffff;
    font-size: 14px;
}

.loginSec ul {
    column-gap: 15px;
}



.loginSec .loginText {
    position: relative;
    column-gap: 20px;
}

.loginSec .loginText:after {
    content: "/";
    position: absolute;
    left: 42%;
    transform: translateX(-42%);
}

.dropdown.languageOption a#dropdownMenuLink {
    background: transparent;
    border: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    color: #ffffff;
}

.dropdown.languageOption a#dropdownMenuLink svg {
    color: #fff;
}

.dropdown.languageOption ul {
    background-color: #ffffff;
    border: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.dropdown.languageOption ul li a {
    color: #333333;
}

/* Top Header End */


/* Brand Start */
.brandSec {
    padding: 10px 0;
    background-color: #ffffff;
}

.menuSec .navbar-brand {
    color: #fff;
}

.brandLogo a {

    width: 200px;
}

/* Brand End */

/* Menus Start */
.navbar .megamenu {
    padding: 1rem;
}

.navbar.navbar-expand-lg.menuSec {
    background-color: var(--dark-bg-blue);
    position: relative;
}

.menusList li a {
    color: #fff;
    text-transform: capitalize;
}

.menusList li a:focus {
    color: #f89c1d;
}

.menusList li a:hover {
    color: #fff;
}

.dropdown-menu.megamenu.show {
    width: 90%;
    margin: auto;
    border: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    padding: 25px;
}

.dropdown-menu.megamenu.show li a {
    color: #333333;
}

.searchIcon svg {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

.megaMenuInfo {
    display: flex;
    justify-content: start;
    column-gap: 15px;
}

.megaMenuInfo .menusDetail {
    width: 20%;
}

.menusDetail .menusBody img {
    height: 121px;
    border-radius: 10px;
}

.megaMenuText h5 a {
    color: #333333;
    font-size: 16px;
    min-height: 45px;
    display: block;
}

.megaMenuText h5 a:hover {
    color: var(--light-blue);
}

.megaMenuText h5 a {
    color: #333333;
    font-size: 16px;
}

.newsName span {
    display: block;
}

.newsName .date {
    font-weight: 500;
}

.newsName .name {
    color: #a7a7a7;
}

.newsName {
    margin-bottom: 10px;
}

.menusFooter {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.menusFooter p {
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.menusFooter p svg {
    color: #a7a7a7;
}

.searchItem {
    position: absolute;
    bottom: -66px;
    width: 300px;
    right: 20px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border-radius: 0 0 4px 4px;
    z-index: 1;
}

.searchWrap {
    position: relative;
}

.searchElm input {
    border-radius: 0;
    height: 45px;
}

.searchElm button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #cf5f00;
    color: #ffffff;
    height: 100%;
    border-radius: 0;
}

.searchElm button:hover {
    background-color: var(--dark-bg-blue);
}

.cityName ul li a {
    background: #ffffff;
    display: block;
    color: #252225 !important;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 5px;
    transition: .4s ease-in-out;
}

.cityName ul li.active a {
    background-color: var(--dark-bg-blue);
    color: #ffffff !important;
}

.cityName ul li a:hover {
    background-color: var(--dark-bg-blue);
    color: #ffffff !important;
}

/* Menus End */

/**** Header End ****/


/**** Footer Start ****/
.footer {
    background-color: var(--black-bg);
    ;
    padding-top: 40px;
}

.text.randomPost {
    display: flex;
    column-gap: 20px;
}

.postFigure figure img {
    border-radius: 8px;
    max-width: 100px;
}

.postContent a {
    color: var(--gray);
}

.postContent a:hover {
    color: var(--dark-bg-blue);
}

.footer-heading.footerTitle {
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--white);
}

.footerSocial ul {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.footerSocial ul li a {
    width: 38px;
    height: 38px;
    color: var(--gray);
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    display: block;
    border-radius: 0.25rem;
    background-color: #2d2e30;
}

.footerSocial ul li a:hover {
    background-color: #f89c1d;
}

.subscribe {
    margin-top: 35px;
}

.subscribe em {
    margin-bottom: 5px;
    color: #8b8b8b;
    font-size: 14px;
}

form.subscribeForm input {
    border-radius: 0;
    height: 45px;
}

form.subscribeForm input:focus {
    box-shadow: none;
}

form.subscribeForm {
    position: relative;
}

form.subscribeForm .subsBtn {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--dark-bg-orange);
    color: #fff;
    height: 100%;
    border-radius: 0;
}

.brandLogo p {
    font-size: 17px;
    margin-top: 20px;
    color: var(--gray);
    margin-top: 25px;
}

.copyright {
    color: var(--gray);
}

.copyright a {
    color: var(--white);
}
.footerList {
    margin-bottom: 20px;
}
.footerList li a {
    color: var(--gray);
    padding: 4px 0;
}
ul.footerList li svg{
    color: var(--dark-bg-orange);
    font-size: 20px;
}
.footerList li a:hover{
    color: var(--dark-bg-orange);;
}
/**** Footer End ****/


/**** Home Page Start ****/


/* Breaking News Start */
.breakingNewsInfo {
    display: flex;
    column-gap: 15px;
    background: #f1f1f1;
    align-items: center;
}

.breakingNewsInfo h5 {
    margin: 0;
    background: #cf5f00;
    padding: 10px;
    color: #fff;
    width: calc(100% - 87%);
}

.breakingNewsText {
    width: 87%;
}

.breakingNewsText p {
    margin: 0;
}

/* Breaking News End */


/* Banner Start */
.bannerDetail {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    column-gap: 15px;
    flex-wrap: wrap;
}

.bannerSlider,
.bannerThumbnail {
    width: 49%;
}

.bannerImg {
    position: relative;
    margin: 0;
}

.bannerImg a {
    display: block;
}

.bannerImg img {
    border-radius: 10px;
    width: -webkit-fill-available;
}

.FigureText {
    position: absolute;
    bottom: 0;
    padding: 20px;
    z-index: 1;
    border-radius: 0 0 10px 10px;
}

.bannerImg:after {
    content: "";
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #333333, #ffffff00);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #333333, #ffffff00);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px;
}

.FigureText h3,
.FigureText h6 {
    color: #fff;
}

.figureFooter .admin {
    display: flex;
    column-gap: 15px;
}

.figureFooter .admin p {
    color: #ffac66;
    margin-bottom: 0;
}

.figureFooter .comments {
    display: flex;
    column-gap: 15px;
}

.figureFooter .comments p {
    color: #fff;
    margin: 0;
    font-size: 12px;
}

.figureFooter .comments p svg {
    margin-right: 6px;
}

.locationNews {
    position: absolute;
    top: 15px;
    left: 15px;

}

.locationNews span {
    background-color: #f89c1d;
    color: #ffffff;
    padding: 7px 12px;
    line-height: 14px;
    text-transform: uppercase;
    border-radius: 4px;
}

.bannerThumbnail {
    display: flex;
    align-items: center;
    column-gap: 15px;
    flex-wrap: wrap;
}

.bannerThumbnailInfo {
    width: 48%;
}

/* Banner End */


/* Category Start */
.categoryTitle {
    border-bottom: 2px solid #f89c1d;
    margin-bottom: 15px;
}

.categoryTitle h3 {
    margin: 0;
    display: inline-block;
    background: #f89c1d;
    font-size: 18px;
    padding: 6px 14px;
    color: #fff;
    text-transform: uppercase;
}

.postItem {
    margin-bottom: 15px;
}

.postFigure {
    margin-bottom: 16px;
}

.postFigure a img {
    border-radius: 4px;
}


.postMeta ul {
    display: flex;
    align-items: center;
    justify-content: start;
}

.postList {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 15px;
}

.postListLeft a img {
    width: 200px;
    border-radius: 5px;
}

.postListLeft {
    margin-bottom: 15px;
    width: 150px;
}

.postListRight {
    padding: 0 10px;
    width: calc(100% - 170px);
}

.postMeta ul li {
    margin-right: 10px;
    font-size: 14px;
    color: #b0b0b0;
}

.postMeta .name a {
    color: var(--light-blue);
}

.postMeta .feedback {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.postMeta .feedback svg {
    color: #b0b0b0;
    font-size: 14px;
}

.postMeta .feedback span {
    font-weight: 500;
    font-size: 14px;
}

.postListRight h6 {
    text-transform: capitalize;
    overflow: hidden;
}

.postText h4 {
    overflow: hidden;
    height: 55px;
    text-transform: capitalize;
}

.postText .desc {
    color: #777777;
    margin: 10px 0;
}

/* Category End */


/* Aside Start */
.asideTitle h4 {
    background: #f89c1d;
    color: #fff;
    padding: 8px 14px;
    text-transform: uppercase;
    font-size: 17px;
}

.popularList {
    border-bottom: 1px solid #dee2e6;
}

.popularList li .nav-link {
    border: 0;
    color: #333333;
}

.popularList li .nav-link.active {
    border-bottom: 2px solid var(--light-blue);
}

.tabContent {
    padding: 15px;
}

.socialButtons ul {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
}

.socialButtons ul li a {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 4px;
    background-color: var(--light-blue);
}

.socialButtons .facebook {
    background-color: #395aa1;
}

.socialButtons .twitter {
    background-color: #02abfa;
}

.socialButtons .instagram {
    background-color: #d622b0;
}

.socialButtons .linkedin {
    background-color: #0270ad;
}

.socialButtons .telegram {
    background-color: #30a9db;
}

.checkBox {
    margin: 10px 0 20px;
}

.button-cnt .btn-custom {
    margin-right: 10px;
    border-radius: 0;
}

.button-cnt .a-view-results {
    color: #3f6bbd;
}

/* Aside End */



/**** Home Page End ****/


/**** Login Page Start ****/
.loginPage {
    display: flex;
    align-items: center;
    column-gap: 15px;
    height: 100vh;
    background: url("https://coloredbrain.com/wp-content/uploads/2016/07/login-background.jpg") no-repeat top / cover;
    justify-content: center;
}

.loginForm {
    width: 27%;
    background: #fff;
    padding: 20px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.loginForm h1 {
    font-size: 28px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
}

.loginItem.mb-3 .form-label {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
}

.loginItem.mb-3 .form-control {
    border-radius: 0;
    height: 45px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.loginItem.mb-3 .form-control:focus {
    border-color: var(--dark-bg-blue);
    box-shadow: none;
}

.loginItem.mb-3 .form-check-label {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.loginItem.mb-3 .form-check-label a {
    font-family: 'Rubik', sans-serif;
}

.btn.loginBtn {
    background: var(--dark-bg-blue);
    width: 100%;
    border-radius: 0;
    color: #fff;
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
}

.forgot {
    text-align: center;
}

.forgot a {
    text-decoration: underline;
    font-family: 'Rubik', sans-serif;
}

/**** Login Page End ****/


/**** Breadcrumb Start ****/
.breadcrumbSec {
    padding: 10px 0;
    background: #f8f7fa;
}

.breadcrumbSec ul {
    margin: 0;
}

.breadcrumbSec ul li a {
    text-transform: capitalize;
}

/**** Breadcrumb End ****/


/**** Category Detail Start ****/
.location a {
    background-color: #f89c1d;
    color: #ffffff;
    padding: 7px 12px;
    line-height: 14px;
    text-transform: uppercase;
    border-radius: 4px;
}

.pageTitle {
    margin: 20px 0 30px;
}

.pageTitle h1 {
    margin-bottom: 20px;
    font-size: 35px;
}

.pageFeedback {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #f8f7fa;
    border-radius: 4px;
    margin-bottom: 30px;
}

.pageTitle p {
    margin: 0;
    font-size: 16px;
}

.pageTitle p a {
    color: #f89c1d;
}

.pageFeedback .admin ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: start;
}

.adminText h6,
.adminPostDate p {
    margin: 0;
}

.pageFeedback .views ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: start;
}

.pageSocial.socialButtons {
    margin-bottom: 20px;
}

.pageSocial.socialButtons ul li a {
    width: 40px;
    height: 40px;
}

.pageDetailText {
    margin: 20px 0 30px;
}

.pagePostImg {
    margin-bottom: 30px;
}

.pagePostImg img {
    border-radius: 10px;
}

.pagePostPara p {
    color: #383838;
    font-size: 16px;
    margin-right: 60px;
}

.adminText img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

.tagWidgets {
    margin-bottom: 15px;
}

.pageFeedback .views ul li {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.pageFeedback .views ul li svg {
    color: #f89c1d;
    font-size: 13px;
}

.pageFeedback .views ul li span {
    font-weight: 500;
    font-size: 15px;
}

.tagWidgets .tag-list li a {
    background: #f8f7fa;
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 5px;
}

/* Pagination  Start*/
.postPagination {
    margin: 50px 0;
}

.article {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
}


.article svg,
.article .articleDirection {
    color: #f89c1d;
}


.postPaginationInfo.prev {
    position: relative;
}

.postPaginationInfo.prev:after {
    height: 100%;
    width: 1px;
    background: #c1c1c1;
    position: absolute;
    right: -12px;
    content: "";
    top: 0;
}

.postPaginationInfo.prev {
    text-align: right;
}

.postPaginationInfo.prev .article {
    justify-content: end;
}

.postPaginationInfo .title {
    font-weight: 500;
    font-size: 15px;
}

.postPaginationInfo .title a {
    color: var(--light-blue);
}

/* Pagination  End*/


/* Post Reaction  Start*/
.reactionRate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.reactionCard {
    width: 100px;
}

.reactionHeader {
    text-align: center;
}

.reactionBody {
    background: #f8f7fa;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 10px 0;
}

.reactionBody .number {
    font-size: 35px;
}

.reactionFooter .reactionBtn {
    background-color: #e6e6e6;
    border-radius: 0;
    width: 100%;
    color: #333333;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.reactionHeader img {
    width: 40px;
}

.reactionFooter .reactionBtn:hover {
    background-color: #f89c1d;
}

.aboutAuthor {
    display: flex;
    column-gap: 15px;
    justify-content: start;
    margin: 40px 0;
    padding: 20px 0;
    border-top: 1px solid #f8f7fa;
    border-bottom: 1px solid #f8f7fa;
}

.aboutAuthorLeft a img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.aboutAuthorRow h5 {
    text-transform: uppercase;
}

/* Post Reaction End*/


/* Post Comment Start*/
.commentsForm .fromGroup .form-label {
    margin: 0;
    font-size: 14px;
}

.commentsForm .fromGroup input {
    height: 45px;
    border-radius: 0;
}

.commentsForm .fromGroup textarea {
    border-radius: 0;
}

.commentsForm .fromGroup button {
    border-radius: 0;
    background-color: var(--dark-bg-blue);
    border-color: var(--dark-bg-blue);
}

/* Post Comment End*/


/* related Posts Start*/
.relatedPostInfo {
    margin-bottom: 15px;
}

.relatedPostListText {
    padding: 15px 0;
}

/* related Posts End*/
/**** Category Detail End ****/


/**** Privacy Detail Start ****/
.privacyInfo h4 {
    font-size: 20px;
    color: var(--black);
}

.privacyInfo ul {
    padding-left: 20px;
    list-style: disc;
    margin-bottom: 10px;
}

/**** Privacy Detail End ****/


/**** Contact Page Start ****/
.contactSec .container-fluid {
    padding: 0;
}
.contactDetails {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.contactAddress,
.contactForm {
    width: 50%;
}
.contactAddress{
    padding: 30px;
}
.addressDetail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 23px;
}
.addressDetail .addressText {
    width: calc(100% - 52%);
    padding: 20px;
}
.addressText h5 {
    text-transform: uppercase;
    font-weight: 500;
}
.addressText p{
    font-size: 16px;
}
.addressText > a{
    text-decoration: underline;
    color: var(--light-bg-blue);
    display: block;
    margin-bottom: 10px;
}
.addressText h6 a{
    display: flex;
    font-weight: 600;
    background-color: var(--light-bg-orange);
    padding: 10px;
    color: var(--black);
    align-items: center;
    column-gap: 7px;
}
.contactForm{
    background-color: #f0f0f0;
    padding: 40px;
}
.contactForm p {
    color: var(--light-orange)
}
.contactForm input, .contactForm textarea{
    border-radius: 0;
}
.contactBtn {
    display: flex;
    justify-content: end;
}
.contactBtn button{
    background-color: var(--dark-bg-blue);
    color: var(--white);
    border-color: var(--dark-bg-blue);
    border-radius: 0;
    padding: 8px 40px;
}
/**** Contact Page End ****/


/**** Error Page Start ****/

.errorPage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.errorPage h1 {
    font-size: 230px;
    line-height: 180px;
    letter-spacing: 23px;
}
.errorPage h3 {
    color: var(--black);
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 600;
}
.errorPage a {
    background-color: var(--dark-bg-orange);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 7px;
}
.errorPage p {
    width: 400px;
    text-align: center;
}
.errorPage a:hover {
    background-color: var(--dark-bg-blue);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 7px;
}
/**** Error Page End ****/