/* ============ desktop view ============ */
@media all and (min-width: 992px) {

    .navbar .has-megamenu{position:static!important;}
    .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }
  
  }
  /* ============ desktop view .end// ============ */
  
  /* ============ mobile view ============ */
  @media(max-width: 992px){
    .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
      overflow-y: auto;
        max-height: 90vh;
        margin-top:10px;
    }
    .topBarInfo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bannerSlider, .bannerThumbnail{
      width: 100%;
    }
  }
  /* ============ mobile view .end// ============ */